import React from 'react';
import { Home } from '../components/'
import { Page } from '../components/shared';
import './main.css';

const App = () => {
  return (
    <main>
      <Page isHome={true}>
        <Home/>
      </Page>
    </main>
  )
}

export default App
